import axios from "axios";

export default class CategoryType {
  constructor() {
    this.base_url = process.env.REACT_APP_URL_ORDER;
    this.name = "/category_types";
    this.url = this.base_url + this.name;
  }

  select = async () => {
    return await axios.get(`${this.url}/select`);
  };
}
