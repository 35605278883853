class Order {
  from_form_to_api = (data) => {
    return {
      ...data,
      characteristics:
        data.characteristics.length === 1 &&
        (!data.characteristics[0].characteristic_type_id ||
          !data.characteristics[0].value)
          ? []
          : data.characteristics,
    };
  };
}

export default new Order();
