import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { CharacteristicType } from "../../services/Common";

import { addTitleOption, createOptions } from "../../utils/select";

const REDUCER_MODULE_NAME = "CHARACTERISTIC_TYPE";

export const initialState = {
  loading: false,
  select: [],
  skus: [],
  error: "",
};

export const select = createAsyncThunk(
  `SELECT_${REDUCER_MODULE_NAME}`,
  async ({ base_url, leaf = "" }) => {
    const service = new CharacteristicType(base_url);

    const { data } = await service.select(leaf);

    return {
      characteristic_types: addTitleOption(createOptions(data)),
    };
  }
);

export const selectV2 = createAsyncThunk(
  `SELECT_${REDUCER_MODULE_NAME}`,
  async ({ base_url, queryParams }) => {
    const service = new CharacteristicType(base_url);

    const { data } = await service.selectV2(queryParams);

    return {
      characteristic_types: addTitleOption(createOptions(data)),
    };
  }
);

export const select_skus = createAsyncThunk(
  `SELECT_SKUS_${REDUCER_MODULE_NAME}`,
  async ({ base_url, leaf }) => {
    const service = new CharacteristicType(base_url);

    const { data } = await service.select(leaf);

    return {
      characteristic_types_skus: addTitleOption(createOptions(data)),
    };
  }
);

const loadingReducer = (state, _) => {
  return {
    ...state,
    loading: true,
  };
};

export const characteristicTypeSlice = createSlice({
  name: REDUCER_MODULE_NAME,
  initialState,
  reducers: {},
  extraReducers: {
    [select.pending.type]: loadingReducer,
    [selectV2.pending.type]: loadingReducer,
    [select_skus.pending.type]: loadingReducer,

    [select.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        select: action.payload.characteristic_types,
      };
    },
    [selectV2.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        select: action.payload.characteristic_types,
      };
    },
    [select_skus.fulfilled.type]: (state, action) => {
      return {
        ...state,
        skus: action.payload.characteristic_types_skus,
      };
    },
  },
});

export default characteristicTypeSlice.reducer;
