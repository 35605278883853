import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Person } from "../../services/Person Management";
import personAdapter from "../../adapters/person";

import { select as select_document_types } from "./document_type";
import { select as select_fiscal_conditions } from "./fiscal_condition";
import { select as select_characteristic_types } from "./characteristic_type";

const service = new Person();

const REDUCER_MODULE_NAME = "PERSON";

const initialState = {
  grid: {
    activos: {
      page: 1,
      skip: 0,
      take: 3,
      rows: 0,
      data: [],
    },
    inactivos: {
      page: 1,
      skip: 0,
      take: 3,
      rows: 0,
      data: [],
    },
  },
  loading: true,
  formIsReady: false,
  data: "",
  message: "",
  error: "",
  select: [],
};

const loadingReducer = (state, _) => {
  return {
    ...state,
    formIsReady: false,
    loading: true,
  };
};

export const grid_activos = createAsyncThunk(
  `GRID_ACTIVOS_${REDUCER_MODULE_NAME}`,
  async ({ skip, take }) => {
    const { data } = await service.grid(skip, take, 1);

    return {
      skip,
      take,
      grid_data: data,
    };
  }
);

export const grid_inactivos = createAsyncThunk(
  `GRID_INACTIVOS_${REDUCER_MODULE_NAME}`,
  async ({ skip, take }) => {
    const { data } = await service.grid(skip, take, 0);

    return {
      skip,
      take,
      grid_data: data,
    };
  }
);

export const getById = createAsyncThunk(
  `GET_${REDUCER_MODULE_NAME}`,
  async (id) => {
    const person = await service.getById(id);

    return {
      person: person.data,
    };
  }
);

export const select = createAsyncThunk(
  `SELECT_${REDUCER_MODULE_NAME}`,
  async () => {
    const { data: people } = await service.select();

    return {
      people: [
        { value: "", label: "Seleccione una opción." },
        ...people.map((item) => ({
          value: item.id,
          label: item.full_name,
        })),
      ],
    };
  }
);

export const changeActive = createAsyncThunk(
  `CHANGE_ACTIVE_${REDUCER_MODULE_NAME}`,
  async ({ id }, { dispatch }) => {
    await service.changeActive(id);

    dispatch(
      grid_activos({
        skip: initialState.grid.activos.skip,
        take: initialState.grid.activos.take,
      })
    );
    dispatch(
      grid_inactivos({
        skip: initialState.grid.inactivos.skip,
        take: initialState.grid.inactivos.take,
      })
    );
  }
);

export const form = createAsyncThunk(
  `FORM_${REDUCER_MODULE_NAME}`,
  async (id, { dispatch }) => {
    dispatch(select_document_types());
    dispatch(select_fiscal_conditions());
    dispatch(select_characteristic_types({ base_url: service.base_url }));

    if (id) {
      dispatch(getById(id));
    }
  }
);

export const submit = createAsyncThunk(
  `SUBIMIT_FORM_${REDUCER_MODULE_NAME}`,
  async ({ id, data }) => {
    const data_to_submit = personAdapter.from_form_to_api(data);

    try {
      if (id) {
        await service.update(id, data_to_submit);
      } else {
        await service.create(data_to_submit);
      }

      return {
        message: `Socio ${id ? "modificado" : "creado"} correctamente.`,
        error: "",
      };
    } catch (error) {
      return {
        error: error.errors,
        message: "",
      };
    }
  }
);

export const personSlice = createSlice({
  name: REDUCER_MODULE_NAME,
  initialState,
  reducers: {
    clean() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: {
    [grid_activos.pending.type]: loadingReducer,
    [grid_inactivos.pending.type]: loadingReducer,
    [getById.pending.type]: loadingReducer,
    [select.pending.type]: loadingReducer,
    [form.pending.type]: loadingReducer,
    [submit.pending.type]: loadingReducer,

    [grid_activos.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: "",
        grid: {
          ...state.grid,
          activos: {
            skip: action.payload.skip,
            take: action.payload.take,
            rows: action.payload.grid_data.rows,
            data: personAdapter.from_api_to_grid(action.payload.grid_data.data),
          },
        },
      };
    },
    [grid_inactivos.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: "",
        grid: {
          ...state.grid,
          inactivos: {
            skip: action.payload.skip,
            take: action.payload.take,
            rows: action.payload.grid_data.rows,
            data: personAdapter.from_api_to_grid(action.payload.grid_data.data),
          },
        },
      };
    },
    [getById.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: "",
        data: action?.payload?.person || "",
      };
    },
    [select.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: "",
        select: action.payload.people,
      };
    },
    [form.fulfilled.type]: (state, action) => {
      return {
        ...state,
        message: "",
        loading: false,
        formIsReady: false,
      };
    },
    [submit.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        formIsReady: !!action.payload.message,
        error: action.payload.error,
      };
    },
  },
});

export const { clean } = personSlice.actions;

export default personSlice.reducer;
