import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import logger from "redux-logger";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createMigrate,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import characteristicValueReducer from "./reducer/characteristic_value";
import characteristicTypeReducer from "./reducer/characteristic_type";
import fiscalConditionReducer from "./reducer/fiscal_condition";
import documentTypeReducer from "./reducer/document_type";
import categoryTypeReducer from "./reducer/category_type";
import categoryReducer from "./reducer/category";
import catalogReducer from "./reducer/catalog";
import sidebarReducer from "./reducer/sidebar";
import profileReducer from "./reducer/profile";
import productReducer from "./reducer/product";
import personReducer from "./reducer/person";
import orderReducer from "./reducer/order";
import authReducer from "./reducer/auth";
import userReducer from "./reducer/user";
import logsReducer from "./reducer/logs";
import { combineReducers } from "redux";

const persistConfig = {
  key: "smart360",
  storage,
  version: 0,
  debug: false,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  characteristic_values: characteristicValueReducer,
  characteristic_type: characteristicTypeReducer,
  fiscal_condition: fiscalConditionReducer,
  document_type: documentTypeReducer,
  category_type: categoryTypeReducer,
  category: categoryReducer,
  catalog: catalogReducer,
  sidebar: sidebarReducer,
  profile: profileReducer,
  product: productReducer,
  catalog: catalogReducer,
  product: productReducer,
  person: personReducer,
  order: orderReducer,
  auth: authReducer,
  user: userReducer,
  logs: logsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const IS_DEV = process.env.NODE_ENV !== "production";

const concatMiddlewares = [thunk];

if (IS_DEV) concatMiddlewares.push(logger);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(concatMiddlewares),
});

export const persistor = persistStore(store);

export default store;
