import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Order, CategoryType } from "../../services/Order Management";
import orderAdapter from "src/adapters/order";

const orderService = new Order();
const orderCategoryType = new CategoryType();

const REDUCER_MODULE_NAME = "ORDER";

const initialState = {
  grid: {
    activos: {
      page: 1,
      skip: 0,
      take: 3,
      rows: 0,
      data: [],
    },
    inactivos: {
      page: 1,
      skip: 0,
      take: 3,
      rows: 0,
      data: [],
    },
  },
  loading: true,
  formIsReady: false,
  date: "",
  orderNumber: "",
  categories: [],
  data: "",
  message: "",
  error: "",
  select: [],
};

const loadingReducer = (state, _) => {
  return {
    ...state,
    formIsReady: false,
    loading: true,
  };
};

export const grid_activos = createAsyncThunk(
  `GRID_ACTIVOS_${REDUCER_MODULE_NAME}`,
  async ({ skip, take }) => {
    const { data } = await orderService.grid(skip, take, 1);

    return {
      skip,
      take,
      grid_data: data,
    };
  }
);

export const grid_inactivos = createAsyncThunk(
  `GRID_INACTIVOS_${REDUCER_MODULE_NAME}`,
  async ({ skip, take }) => {
    const { data } = await orderService.grid(skip, take, 0);

    return {
      skip,
      take,
      grid_data: data,
    };
  }
);

export const getById = createAsyncThunk(
  `GET_${REDUCER_MODULE_NAME}`,
  async (id) => {
    const person = await orderService.getById(id);

    return {
      person: person.data,
    };
  }
);

export const changeActive = createAsyncThunk(
  `CHANGE_ACTIVE_${REDUCER_MODULE_NAME}`,
  async ({ id }, { dispatch }) => {
    await orderService.changeActive(id);

    dispatch(
      grid_activos({
        skip: initialState.grid.activos.skip,
        take: initialState.grid.activos.take,
      })
    );
    dispatch(
      grid_inactivos({
        skip: initialState.grid.inactivos.skip,
        take: initialState.grid.inactivos.take,
      })
    );
  }
);

export const orderNumber = createAsyncThunk(
  `ORDER_NUMBER_${REDUCER_MODULE_NAME}`,
  async (categoryId, { dispatch }) => {
    const { data: orderNumber } = await orderService.numeroDeComprobante(
      categoryId
    );

    return {
      orderNumber,
    };
  }
);

export const form = createAsyncThunk(
  `FORM_${REDUCER_MODULE_NAME}`,
  async (id, { dispatch }) => {
    const [{ data: date }, { data: categories }] = await Promise.all([
      orderService.today(),
      orderCategoryType.select(),
    ]);

    if (id) {
      dispatch(getById(id));
    }

    return {
      date,
      categories: [
        { value: "", label: "Seleccione una opción." },
        ...categories.map((item) => ({
          value: item.id,
          label: item.value,
        })),
      ],
    };
  }
);

export const submit = createAsyncThunk(
  `SUBIMIT_FORM_${REDUCER_MODULE_NAME}`,
  async ({ id, data }) => {
    const data_to_submit = orderAdapter.from_form_to_api(data);

    try {
      if (id) {
        await orderService.update(id, data_to_submit);
      } else {
        await orderService.create(data_to_submit);
      }

      return {
        message: `Orden ${id ? "modificada" : "creada"} correctamente.`,
        error: "",
      };
    } catch (error) {
      return {
        message: "",
        error: error.errors,
      };
    }
  }
);

export const orderSlice = createSlice({
  name: REDUCER_MODULE_NAME,
  initialState,
  reducers: {
    clean() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: {
    [grid_activos.pending.type]: loadingReducer,
    [grid_inactivos.pending.type]: loadingReducer,
    //[getById.pending.type]: loadingReducer,
    [form.pending.type]: loadingReducer,
    [orderNumber.pending.type]: loadingReducer,
    [submit.pending.type]: loadingReducer,
    [grid_activos.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: "",
        grid: {
          ...state.grid,
          activos: {
            skip: action.payload.skip,
            take: action.payload.take,
            rows: action.payload.grid_data.rows,
            data: action.payload.grid_data.data,
          },
        },
      };
    },
    [grid_inactivos.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: "",
        grid: {
          ...state.grid,
          inactivos: {
            skip: action.payload.skip,
            take: action.payload.take,
            rows: action.payload.grid_data.rows,
            data: action.payload.grid_data.data,
          },
        },
      };
    },
    /*[getById.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: "",
        data: action?.payload?.person || "",
      };
    },*/
    [form.fulfilled.type]: (state, action) => {
      return {
        ...state,
        message: "",
        loading: false,
        formIsReady: false,
        date: action.payload.date,
        categories: action.payload.categories,
      };
    },
    [orderNumber.fulfilled.type]: (state, action) => {
      return {
        ...state,
        message: "",
        loading: false,
        formIsReady: false,
        orderNumber: action.payload.orderNumber,
      };
    },
    [submit.fulfilled.type]: (state, action) => {
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        formIsReady: !!action.payload.message,
        error: action.payload.error,
      };
    },
  },
});

export const { clean } = orderSlice.actions;

export default orderSlice.reducer;
